import { Button, Card, CardActions, CardContent, CardHeader, deprecatedPropType, Grid, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteClass, deleteGen, deleteType, deleteWarranty, depGen, getClass, getGen, getType, getWorrantys, setClass, setGen, setType, setWorrantys } from '../../store/Machine/actions'
import * as MachineSelector from "../../store/Machine/selectors"
import AddForm from './AddForm'
import AddFormType from './AddFormType'
import DeleteIcon from '@mui/icons-material/DeleteForeverTwoTone'
import AddModel from './AddModel'
import AddFormGen from './AddFormGen'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Box } from '@mui/system'
import EditIcon from '@mui/icons-material/Edit'
import AddFormWorranty from './AddFormWorranty'

function Params() {
    const type = useSelector(MachineSelector.type)
    const classList = useSelector(MachineSelector.classList)
    const gen = useSelector(MachineSelector.gen)
    const worrantys = useSelector(MachineSelector.worrantys)

    const dispatch = useDispatch()

    const [addClass, setAddClass] = useState(false)
    const [addType, setAddType] = useState(false)
    const [addModel, setAddModel] = useState(false)
    const [addGen, setAddGen] = useState(false)
    const [addWorranty, setAddWorranty] = useState(false)

    const [id, setId] = useState(null)

    useEffect(() => {
        if (!addWorranty)
            setId(null)
    }, [addWorranty])

    useEffect(() => {
        dispatch(getType())
        dispatch(getClass())
        dispatch(getGen())
        dispatch(getWorrantys())

        return () => {
            dispatch(setType([]))
            dispatch(setClass([]))
            dispatch(setGen([]))
            dispatch(setWorrantys([]))
        }
    }, [])

    return (
        <>
            <Box>
                <Typography variant="h5" component="h5" sx={{ mb: '24px' }}>
                    Классы
                </Typography>
                <Grid container spacing={{ xs: 2, md: 2 }}>
                    {
                        type.map(item => (
                            <Grid item key={item.id} xs={12} sm={12} md={3}>
                                <Card sx={{ width: "100%" }}>
                                    <CardContent>
                                        <CardHeader
                                            sx={{
                                                "& .MuiCardHeader-title": {
                                                    fontSize: "16px",
                                                    fontWeight: "500"
                                                }
                                            }}
                                            action={
                                                <IconButton edge="end" aria-label="delete" onClick={() => dispatch(deleteType(item.id))}>
                                                    <DeleteIcon sx={{
                                                        color: "#f60607",
                                                    }} />
                                                </IconButton>
                                            }
                                            title={item.title}
                                        />
                                        <List dense={true}>
                                            {
                                                classList.map(classitem => {
                                                    if (classitem.type_id === item.id)
                                                        return (
                                                            <ListItem
                                                                key={classitem.id}
                                                                secondaryAction={
                                                                    <IconButton edge="end" aria-label="delete" onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        dispatch(deleteClass(classitem.id))
                                                                    }}>
                                                                        <DeleteIcon sx={{
                                                                            width: '20px',
                                                                            height: '20px',
                                                                            color: "#f60607",
                                                                        }} />
                                                                    </IconButton>
                                                                }
                                                                onClick={e => {
                                                                    e.stopPropagation()
                                                                    setAddModel(true)
                                                                    setId(classitem.id)
                                                                }}
                                                                sx={{
                                                                    cursor: "pointer"
                                                                }}
                                                            >
                                                                <ListItemText primary={classitem.title} />
                                                            </ListItem>
                                                        )
                                                })
                                            }
                                        </List>
                                        <Button size="small" variant="contained" color="custom_purple" onClick={e => {
                                            setAddClass(true)
                                            setId(item.id)
                                        }}>Добавить класс</Button>
                                        <List dense={true}>
                                            {
                                                gen.map(genitem => {
                                                    if (genitem.type_id === item.id)
                                                        return (
                                                            <ListItem
                                                                key={genitem.id}
                                                                secondaryAction={
                                                                    <>
                                                                        <IconButton edge="end" aria-label="delete" sx={{
                                                                            mr: '5px'
                                                                        }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                dispatch(depGen(genitem.id, !genitem.deprecated))
                                                                            }}>
                                                                            {
                                                                                !genitem.deprecated ? <VisibilityOffIcon sx={{
                                                                                    width: '20px',
                                                                                    height: '20px',
                                                                                    color: '#a4a4a4'
                                                                                }} /> : <VisibilityIcon sx={{
                                                                                    width: '20px',
                                                                                    height: '20px',
                                                                                    color: '#a4a4a4'
                                                                                }} />
                                                                            }
                                                                        </IconButton>
                                                                        <IconButton edge="end" aria-label="delete" onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            dispatch(deleteGen(genitem.id))
                                                                        }}>
                                                                            <DeleteIcon sx={{
                                                                                width: '20px',
                                                                                height: '20px',
                                                                                color: "#f60607",
                                                                            }} />
                                                                        </IconButton>
                                                                    </>
                                                                }
                                                            >
                                                                <ListItemText primary={genitem.title} />
                                                            </ListItem>
                                                        )
                                                })
                                            }
                                        </List>
                                        <Button size="small" variant="contained" color="custom_purple" onClick={e => {
                                            setAddGen(true)
                                            setId(item.id)
                                        }}>Добавить поколение</Button>
                                    </CardContent>
                                    <CardActions>

                                    </CardActions>
                                </Card>
                            </Grid>
                        ))
                    }
                    <Grid item xs={12} sm={12} md={3}>
                        <Card sx={{ width: "100%" }}>
                            <CardActions>
                                <Button size="small" onClick={e => setAddType(true)}>Добавить</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid >
                <Typography variant="h5" component="h5" sx={{ mb: '24px', mt: '24px' }}>
                    Другое
                </Typography>
                <Box>
                    <Card sx={{ width: "30%" }}>
                        <CardContent>
                            <CardHeader
                                sx={{
                                    "& .MuiCardHeader-title": {
                                        fontSize: "16px",
                                        fontWeight: "500"
                                    }
                                }}
                                title={'Гарантии'}
                            />
                            {
                                worrantys.map(item => (
                                    <List dense={true} key={item.id}>
                                        <ListItem
                                            secondaryAction={
                                                <>
                                                    <IconButton edge="end" aria-label="edit" sx={{ mr: '10px' }} onClick={() => {
                                                        setId(item.id)
                                                        setAddWorranty(true)
                                                    }}>
                                                        <EditIcon sx={{
                                                            width: '20px',
                                                            height: '20px',
                                                        }} />
                                                    </IconButton>
                                                    <IconButton edge="end" aria-label="delete" onClick={() => dispatch(deleteWarranty(item.id))}>
                                                        <DeleteIcon sx={{
                                                            width: '20px',
                                                            height: '20px',
                                                            color: "#f60607",
                                                        }} />
                                                    </IconButton>
                                                </>
                                            }
                                        >
                                            <ListItemText primary={item.title} secondary={`${item.month}/${item.hours}`} />
                                        </ListItem>
                                    </List>
                                ))
                            }
                            <Button size="small" variant="contained" color="custom_purple" onClick={() => setAddWorranty(true)}>Добавить гарантию</Button>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            <AddForm open={addClass} setOpen={setAddClass} id={id} />
            <AddFormGen open={addGen} setOpen={setAddGen} id={id} />
            <AddFormType open={addType} setOpen={setAddType} />
            <AddModel open={addModel} setOpen={setAddModel} id={id} />
            <AddFormWorranty open={addWorranty} setOpen={setAddWorranty} id={id} />
        </>
    )
}

export default Params