import {
  SET_CLASS,
  SET_GEN,
  SET_GEN_TYPE,
  SET_MACHINES,
  SET_MACHINE_INFO,
  SET_MANAGER,
  SET_MODAL,
  SET_MODELS,
  SET_PERIOD,
  SET_SELLERS,
  SET_TYPE,
  SET_WORRANTY,
  SET_WORRANTYS,
} from "./types";

const initialState = {
  machines: [],
  type: [],
  class: [],
  modal: [],
  gen: [],
  worrantys: [],
  period: [],
  models: [],
  gen_type: [],
  machine_info: [],
  sellers: [],
  manager: [],
  worranty: [],
};

export const MachineReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MACHINES:
      return { ...state, machines: action.payload };
    case SET_TYPE:
      return { ...state, type: action.payload };
    case SET_CLASS:
      return { ...state, class: action.payload };
    case SET_MODAL:
      return { ...state, modal: action.payload };
    case SET_GEN:
      return { ...state, gen: action.payload };
    case SET_GEN_TYPE:
      return { ...state, gen_type: action.payload };
    case SET_WORRANTYS:
      return { ...state, worrantys: action.payload };
    case SET_WORRANTY:
      return { ...state, worranty: action.payload };
    case SET_PERIOD:
      return { ...state, period: action.payload };
    case SET_MODELS:
      return { ...state, models: action.payload };
    case SET_MACHINE_INFO:
      return { ...state, machine_info: action.payload };
    case SET_SELLERS:
      return { ...state, sellers: action.payload };
    case SET_MANAGER:
      return { ...state, manager: action.payload };
    default:
      return state;
  }
};
