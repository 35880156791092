import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { addType, getParts } from '../../store/Passport/actions';
import * as PassportSelector from "../../store/Passport/selectors"

function AddForm({ open, setOpen, id }) {
    const dispatch = useDispatch()

    const [part, setPart] = useState();

    const parts = useSelector(PassportSelector.parts)

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        dispatch(getParts())
    }, [])

    const handleSend = (e) => {
        e.preventDefault()

        dispatch(addType({
            "machine_type": id,
            "passport_part": part
        }))

        setOpen(false)
        setPart()
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <form
                onSubmit={(e) => handleSend(e)}
                autoComplete="off"
            >
                <DialogTitle>
                    Добавить класс
                    <IconButton
                        aria-label="close"
                        onClick={() => handleClose(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <TextField
                            margin="dense"
                            select
                            label="Разделы"
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            value={part}
                            onChange={(e) => setPart(parseInt(e.target.value))}
                            required={true}
                        >
                            <option value={0}>Выбрать...</option>
                            {
                                parts.map(item => item.parent === null ? <option key={item.id} value={item.id}>{item.title} - {item.comment}</option> : null)
                            }
                        </TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Добавить</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddForm