import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { addWarranty, editWarranty, getWarranty, setWarranty } from '../../store/Machine/actions'
import * as MachineSelector from '../../store/Machine/selectors'

function AddFormWorranty({ open, setOpen, id = null }) {
    const dispatch = useDispatch()

    const [title, setTitle] = useState('');
    const [month, setMonth] = useState(1);
    const [hours, setHours] = useState(100);

    const worranty = useSelector(MachineSelector.worranty)

    const handleClose = () => {
        setOpen(false)
        setTitle('')
        setMonth(1)
        setHours(100)
    };

    useEffect(() => {
        if (id !== null) {
            dispatch(getWarranty(id))
        }

        return () => {
            dispatch(setWarranty([]))
        }
    }, [id])

    useEffect(() => {
        if (worranty !== []) {
            setTitle(worranty.title)
            setMonth(worranty.month)
            setHours(worranty.hours)
        }
    }, [worranty])

    const handleSend = (e) => {
        e.preventDefault()

        const data = {
            title: title,
            hours: month,
            month: hours
        }

        if (id !== null) {
            dispatch(editWarranty(id, data))
        } else {
            dispatch(addWarranty(data))
        }

        setOpen(false)
        setTitle('')
        setMonth(1)
        setHours(100)
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <form
                onSubmit={(e) => handleSend(e)}
                autoComplete="off"
            >
                <DialogTitle>
                    {
                        id === null ? 'Добавить гарантию' : 'Редактировать'
                    }
                    <IconButton
                        aria-label="close"
                        onClick={() => handleClose(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <TextField
                            margin="dense"
                            autoFocus
                            label="Название"
                            type="text"
                            fullWidth
                            variant="outlined"
                            required={true}
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Месяцы"
                            type="text"
                            inputProps={{
                                type: 'number',
                                min: 1
                            }}
                            fullWidth
                            variant="outlined"
                            required={true}
                            value={month}
                            onChange={e => setMonth(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Моточасы"
                            type="text"
                            inputProps={{
                                type: 'number',
                                step: 100
                            }}
                            fullWidth
                            variant="outlined"
                            required={true}
                            value={hours}
                            onChange={e => setHours(e.target.value)}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">{id !== null ? 'Сохранить' : 'Добавить'}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddFormWorranty