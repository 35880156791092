import { Box, Button, Fab, Grid, Pagination, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../components'
import { getClass, getGen, getMachines, getModal, getPeriod, getType, getWorrantys, serchMachine, setClass, setGen, setModal, setPeriod, setType, setWorrantys } from '../../store/Machine/actions'
import * as MachineSelector from "../../store/Machine/selectors"
import AddIcon from '@mui/icons-material/Add'
import AddForm from './AddForm'
import { setMachines } from '../../store/Machine/actions'
import { useLocation, useNavigate } from 'react-router-dom'
import CircleIcon from '@mui/icons-material/Circle'

function Machine() {
    const machines = useSelector(MachineSelector.machines)

    const [type_id, setType_id] = useState(0)
    const [page, setPage] = useState(1)
    const type = useSelector(MachineSelector.type)
    const classList = useSelector(MachineSelector.classList)
    const modal = useSelector(MachineSelector.modal)
    const gen = useSelector(MachineSelector.gen)
    const worrantys = useSelector(MachineSelector.worrantys)

    const [open, setOpen] = useState(false)
    const [machine_nomer, setMachineNomer] = useState('')

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const ref = useRef()

    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const qPage = query.get('page') !== null ? query.get('page') : 1
        const qType = query.get('type') !== null ? query.get('type') : 0

        if (qPage !== null || qType !== null) {
            setPage(parseInt(qPage))
            setType_id(parseInt(qType))
            dispatch(getMachines(parseInt(qPage), parseInt(qType)))
            navigate(`${location.pathname}?page=${qPage !== 0 ? qPage : 1}${qType !== 0 ? '&type=' + qType : ''}`)
        } else {
            dispatch(getMachines())
        }
        dispatch(getType())
        dispatch(getClass())
        dispatch(getModal())
        dispatch(getGen())
        dispatch(getWorrantys())
        dispatch(getPeriod())

        return () => {
            dispatch(setMachines([]))
            dispatch(setType([]))
            dispatch(setClass([]))
            dispatch(setModal([]))
            dispatch(setGen([]))
            dispatch(setWorrantys([]))
            dispatch(setPeriod([]))
        }
    }, [])

    const handlePage = (page) => {
        setPage(page)
        dispatch(getMachines(page, type_id))
        ref.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
        navigate(`${location.pathname}?page=${page}${type_id !== 0 ? '&type=' + type_id : ''}`)
    }

    const filter = () => {
        setPage(1)
        dispatch(getMachines(1, type_id))
        navigate(`${location.pathname}?page=${1}${type_id !== 0 ? '&type=' + type_id : ''}`)
    }

    const next = (item) => {
        navigate(`/passport/${item}`)
    }

    const imei = (item) => {
        return item.imei ? item.imei : <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}><CircleIcon sx={{ color: "#EB5757", width: '15px' }} /></Box>
    }

    const cols = [
        { title: "Машина", code: 'nomer', type: 'link', fun: next },
        { title: "Номер заявки", code: 'nomer_1c' },
        { title: 'Тип', code: 'type_id', vars: 'type' },
        { title: 'Класс', code: 'classes_id', vars: 'classList' },
        { title: 'Модель', code: 'model', vars: 'modal' },
        { title: 'Поколение', code: 'generation', vars: 'gen' },
        { title: 'Гарантия', code: 'warranty', vars: 'worrantys' },
        { title: 'Военная', code: 'military' },
        { title: 'imei', val: imei }
    ]

    const parans = {
        type,
        classList,
        modal,
        gen,
        worrantys
    }

    return (
        <>
            <Grid container
                columnSpacing={3}
                ref={ref}
                sx={{
                    overflow: 'auto',
                    height: 'calc(100vh - 129px)'
                }}
            >
                <Grid item xs={2} sx={{
                    height: '100%'
                }}>
                    <Box
                        sx={{
                            background: "#f5f6fa",
                            padding: "20px 24px",
                            borderRadius: '4px',
                            marginBottom: "24px"
                        }}
                    >
                        <h3
                            style={{
                                margin: "0",
                                marginBottom: "10px"
                            }}
                        >Поиск по номеру</h3>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Номер"
                            type="text"
                            fullWidth
                            variant="outlined"
                            size='small'
                            value={machine_nomer}
                            onChange={e => setMachineNomer(e.target.value)}
                        />
                        <Button onClick={() => dispatch(serchMachine(machine_nomer, navigate))} variant="contained" color="custom_purple">Найти</Button>
                    </Box>
                    <Box
                        sx={{
                            background: "#f5f6fa",
                            padding: "20px 24px",
                            borderRadius: '4px'
                        }}
                    >
                        <h3
                            style={{
                                margin: "0",
                                marginBottom: "10px"
                            }}
                        >Фильтр</h3>

                        <TextField
                            id="select-currency-native"
                            margin="dense"
                            select
                            label="Тип машины"
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={type_id}
                            onChange={e => setType_id(parseInt(e.target.value))}
                        >
                            <option value={0}>Выбрать</option>
                            {type.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.title}
                                </option>
                            ))}
                        </TextField>
                        <Button variant="contained" color="custom_purple" onClick={filter} >Применить</Button>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <Table cols={cols} data={typeof machines.data !== 'undefined' ? machines.data : []} params={parans} />
                    <Pagination
                        sx={{
                            marginTop: '24px'
                        }}
                        count={typeof machines.last_page !== 'undefined' ? machines.last_page : 0}
                        page={page}
                        shape="rounded"
                        color="custom_purple"
                        onChange={(event, page) => handlePage(page)}
                    />
                </Grid>
            </Grid>
            <Fab color='custom_purple' aria-label="add" sx={{
                position: 'fixed',
                bottom: '50px',
                right: '50px'
            }} onClick={() => setOpen(true)}>
                <AddIcon />
            </Fab>
            <AddForm open={open} setOpen={e => setOpen(e)} />
        </>
    )
}

export default Machine