import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, TextField } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { addClass } from '../../store/Machine/actions';
import { setSection } from '../../store/Passport/actions';

function AddForm({ open, setOpen }) {
    const dispatch = useDispatch()

    const [title, setTitle] = useState('')
    const [sort, setSort] = useState('')
    const [comment, setComment] = useState('')

    const handleClose = () => {
        setOpen(false);
    };

    const handleSend = (e) => {
        e.preventDefault()

        dispatch(setSection({
            "title": title,
            "sort": sort,
            "comment": comment,
        }))

        setOpen(false)
        setTitle('')
        setSort('')
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <form
                onSubmit={(e) => handleSend(e)}
                autoComplete="off"
            >
                <DialogTitle>
                    Добавить раздел
                    <IconButton
                        aria-label="close"
                        onClick={() => handleClose(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="nomer"
                            label="Раздел"
                            type="text"
                            fullWidth
                            variant="outlined"
                            required={true}
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="sort"
                            label="Сортировка"
                            type="number"
                            fullWidth
                            variant="outlined"
                            required={true}
                            value={sort}
                            onChange={e => setSort(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Комментарий"
                            multiline
                            maxRows={4}
                            required={true}
                            value={comment}
                            onChange={e => setComment(e.target.value)}
                            variant="outlined"
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Добавить</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddForm