import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, RadioGroup, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import * as MachineSelector from "../../store/Machine/selectors"
import { addMachine, getGenType, getModel } from '../../store/Machine/actions';

function AddForm({ group = '', index, services = [], open, setOpen }) {
    const dispatch = useDispatch()

    const modal = useSelector(MachineSelector.modal)
    const gen = useSelector(MachineSelector.gen_type)
    const worrantys = useSelector(MachineSelector.worrantys)
    const period = useSelector(MachineSelector.period)

    const [worrantyStatus, setWorrantyStatus] = useState(false)
    const [millitary, setMillitary] = useState(false)

    const [nomer, setNomer] = useState('');

    const [nomer1c, setNomer1C] = useState('');

    const [_model, setModel] = useState(0);
    const [_modelReal, setModelReal] = useState(0);
    const [_modelError, setModelError] = useState(false);

    const [complect, setComplect] = useState('');

    const [_gen, setGen] = useState(0);
    const [_genError, setGenError] = useState(false);

    const [_worranty, setWorranty] = useState();
    const [to, setTo] = useState();

    const [volume, setVolume] = useState('');

    useEffect(() => {
        if (_model !== 0)
            modal.map((item) => {
                if (item.id === _model)
                    dispatch(getModel(item.classes_id))
            })
    }, [_model])

    const handleClose = () => {
        setOpen(false);
    };

    const handleSend = (e) => {
        e.preventDefault()

        if (_model === 0) {
            setModelError(true)
            return false
        }

        if (_gen === 0) {
            setGenError(true)
            return false
        }

        handleClose(false)

        dispatch(addMachine({
            "nomer": nomer,
            "nomer_1c": nomer1c,
            "model": _model,
            "real_model_id": _modelReal,
            "complectation": complect,
            "generation": _gen,
            "warranty": _worranty,
            "warranty_status": worrantyStatus,
            "period_to": to,
            "fuel_volume": volume,
            "military": millitary
        }))
        setOpen(false)
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <form
                onSubmit={(e) => handleSend(e)}
                autoComplete="off"
            >
                <DialogTitle>
                    Добавить машину
                    <IconButton
                        aria-label="close"
                        onClick={() => handleClose(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="nomer"
                            label="Номер"
                            type="text"
                            fullWidth
                            variant="outlined"
                            required={true}
                            value={nomer}
                            onChange={e => setNomer(e.target.value)}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="nomer"
                            label="Номер 1c"
                            type="text"
                            fullWidth
                            variant="outlined"
                            required={true}
                            value={nomer1c}
                            onChange={e => setNomer1C(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            select
                            label="Модель"
                            SelectProps={{
                                native: true,
                            }}
                            error={_modelError}
                            required={true}
                            variant="outlined"
                            value={_model}
                            onChange={e => {
                                setModel(parseInt(e.target.value))
                                setModelError(false)
                            }}
                        >
                            <option value={0}>Выбрать</option>
                            {modal.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.title}
                                </option>
                            ))}
                        </TextField>
                        <TextField
                            margin="dense"
                            select
                            label="Модель Реальная"
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            value={_modelReal}
                            onChange={e => {
                                setModelReal(parseInt(e.target.value))
                            }}
                        >
                            <option value={0}>Выбрать</option>
                            {modal.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.title}
                                </option>
                            ))}
                        </TextField>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="complectation"
                            label="Комплектация"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={complect}
                            onChange={e => setComplect(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            select
                            label="Поколение"
                            SelectProps={{
                                native: true,
                            }}
                            required={true}
                            error={_genError}
                            variant="outlined"
                            value={_gen}
                            onChange={e => {
                                setGen(parseInt(e.target.value))
                                setGenError(false)
                            }}
                        >
                            <option value={0}>Выбрать</option>
                            {gen.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.title}
                                </option>
                            ))}
                        </TextField>
                        <TextField
                            margin="dense"
                            select
                            label="Гарантия"
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            value={_worranty}
                            onChange={e => setWorranty(parseInt(e.target.value))}
                        >
                            <option value={0}>Выбрать</option>
                            {worrantys.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.title} ({option.hours})
                                </option>
                            ))}
                        </TextField>
                        <FormLabel id="radio-buttons-group-label">Статус гарантии</FormLabel>
                        <FormControlLabel value="Активна" control={<Checkbox checked={worrantyStatus} onChange={() => setWorrantyStatus(!worrantyStatus)} />} label="Активна" />
                        <TextField
                            margin="dense"
                            select
                            label="Период ТО"
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            value={to}
                            onChange={e => setTo(parseInt(e.target.value))}
                        >
                            <option value={0}>Выбрать</option>
                            {period.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.hours}
                                </option>
                            ))}
                        </TextField>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="fuel_volume"
                            label="Объем бака"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={volume}
                            onChange={e => setVolume(e.target.value)}
                        />
                        <FormControlLabel value="Военная" control={<Checkbox checked={millitary} onChange={() => setMillitary(!millitary)} />} label="Военная" />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Добавить</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddForm