export const machines = state => state.MachineReducer.machines
export const type = state => state.MachineReducer.type
export const classList = state => state.MachineReducer.class
export const modal = state => state.MachineReducer.modal
export const gen = state => state.MachineReducer.gen
export const worrantys = state => state.MachineReducer.worrantys
export const worranty = state => state.MachineReducer.worranty
export const period = state => state.MachineReducer.period
export const models = state => state.MachineReducer.models
export const gen_type = state => state.MachineReducer.gen_type
export const machine_info = state => state.MachineReducer.machine_info
export const sellers = state => state.MachineReducer.sellers
export const manager = state => state.MachineReducer.manager