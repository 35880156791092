import { toast } from "react-toastify";
import api from "../../services/api";

import {
    SET_SECTIONS, SET_SECTIONS_TEAMPLATE, SET_ITEMS, SET_SEARCH, SET_PART, SET_PASSPORT_SECTION, SET_PASSPORT_PARENT, SET_VALUE, SET_NEW_VALUE
} from "./types";

export const setValues = data => {
    return {
        type: SET_VALUE,
        payload: data
    }
}
export const setNewValues = data => {
    return {
        type: SET_NEW_VALUE,
        payload: data
    }
}

export const setTypesSection = data => {
    return {
        type: SET_SECTIONS,
        payload: data
    }
}

export const getTypesSection = (id) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/passport/types/${id}`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setTypesSection(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setSections = data => {
    return {
        type: SET_SECTIONS_TEAMPLATE,
        payload: data
    }
}

export const getSection = () => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/passport/parts`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setSections(json))
        }
    } catch (e) {
        console.error(e)
    }
}


export const setSection = (item) => async (dispatch, getState) => {
    try {
        const response = await api.post('/api/v1/passport/parts', item)

        if (response.status === 201) {
            dispatch(getSection())
        }
    } catch (e) {
        console.error(e)
    }
}

export const deleteSection = (id) => async (dispatch, getState) => {
    try {
        const response = await api.delete(`/api/v1/passport/parts/${id}`)

        if (response.status === 200) {
            dispatch(getSection())
        }
    } catch (e) {
        toast.error('Не удалось удалить раздел')
        console.error(e)
    }
}

export const setItems = data => {
    return {
        type: SET_ITEMS,
        payload: data
    }
}

export const getItems = (id) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/passport/links/${id}`)

        if (response.status === 200) {
            const json = await response.data

            setTimeout(() => {
                dispatch(setItems(json))
            }, 200);
        }
    } catch (e) {
        console.error(e)
    }
}

export const setSearch = data => {
    return {
        type: SET_SEARCH,
        payload: data
    }
}

export const getItemsForCode = (code) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/passport/items/code/${code}`)

        if (response.status === 200) {
            const json = await response.data

            dispatch(setSearch(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const addItem = (item) => async (dispatch, getState) => {
    try {
        const response = await api.post(`/api/v1/passport/items`, item)

        if (response.status === 201) {
            dispatch(getItems(item.part_id))
        }
    } catch (e) {
        console.error(e)
    }
}

export const deleteLink = (item) => async (dispatch, getState) => {
    try {
        const response = await api.delete(`/api/v1/passport/links/${item.id}`)

        if (response.status === 200) {
            dispatch(getItems(item.part_id))
        }
    } catch (e) {
        toast.error('Не удалось удалить элемент')
        console.error(e)
    }
}

export const setParts = data => {
    return {
        type: SET_PART,
        payload: data
    }
}

export const getParts = () => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/passport/parts`)

        if (response.status === 200) {
            const json = await response.data

            dispatch(setParts(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const addType = (item) => async (dispatch, getState) => {
    try {
        const response = await api.post(`/api/v1/passport/types`, item)

        if (response.status === 201) {
            dispatch(getTypesSection(item.machine_type))
        }
    } catch (e) {
        console.error(e)
    }
}

export const deleteType = (item) => async (dispatch, getState) => {
    try {
        const response = await api.delete(`/api/v1/passport/types/${item.id}`)

        if (response.status === 200) {
            dispatch(getTypesSection(item.section))
        }
    } catch (e) {
        toast.error('Не удалось удалить элемент')
        console.error(e)
    }
}

export const setPassportParent = data => {
    return {
        type: SET_PASSPORT_PARENT,
        payload: data
    }
}

export const getPassportParent = (nomer) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/passport/${nomer}/template`)

        if (response.status === 200) {
            const json = await response.data

            dispatch(setPassportParent(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setPassportSection = data => {
    return {
        type: SET_PASSPORT_SECTION,
        payload: data
    }
}

export const getPassportSection = (nomer, id) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/passport/${nomer}/template/${id}`)

        if (response.status === 200) {
            const json = await response.data

            setTimeout(() => {
                dispatch(setPassportSection(json))
            }, 200);
        }
    } catch (e) {
        console.error(e)
    }
}

export const addSection = (items, nomer) => async (dispatch, getState) => {
    console.log(items)
    /* try {
        let data = {};
        if (items.length !== 0) {
            for (const key in items) {
                const element = items[key]
                data[`section[${key}][part]`] = element.part
                data[`section[${key}][item]`] = element.item
                data[`section[${key}][value]`] = element.value
                data[`section[${key}][count]`] = element.count
            }
        }
        const response = await api.post(`/api/v1/passport/${nomer}`, data)

        if (response.status === 201) {
            toast.success('Данные обновленны')
        }
    } catch (e) {
        console.error(e)
    } */
}

export const sectionCopyFeld = (item) => (dispatch, getState) => {
    const passportSection = getState().PassportReducer.passportSection
    const value = getState().PassportReducer.value

    const child = passportSection.find(field => {
        if (field.parent_id === item.id)
            return field
    })

    let count = 0

    for (const key in passportSection) {
        if (passportSection[key].id === item.id) {
            count++
        }
    }

    const elem = {
        id: item.id,
        title: item.title,
        type: item.type,
        parent_id: item.parent_id,
        parent: item.parent,
        multiply: 0,
        items: item.items,
        sort: item.sort + 1,
        comment: item.comment,
        count: count
    }

    let newchild = {}
    if (typeof child !== "undefined") {
        newchild = {
            id: child.id,
            title: child.title,
            type: child.type,
            parent_id: child.parent_id,
            parent: child.parent,
            multiply: 0,
            sort: elem.sort,
            comment: child.comment,
            count: count
        }
    }

    const section = [
        ...passportSection,
        elem,
        newchild
    ]

    section.sort((a, b) => a.sort - b.sort)

    const intfield = section.indexOf(elem)
    let strfield = 0
    if (typeof child !== "undefined") {
        strfield = section.indexOf(newchild)
    }


    let newValue = []

    for (const i in value) {
        if (Object.hasOwnProperty.call(value, i)) {
            const element = value[i];
            newValue.push(element)
        }
    }

    newValue.splice(intfield, 0, {
        part: elem.id,
        item: 0,
        value: '',
        count: elem.count
    })

    if (typeof child !== "undefined") {
        newValue.splice(strfield, 0, {
            part: newchild.id,
            item: 0,
            value: '',
            count: newchild.count
        })
    }

    //console.log(newValue)

    dispatch(setNewValues(newValue))
    dispatch(setPassportSection(section))
}

export const updateSubSection = (item, id) => async (dispatch, getState) => {
    try {
        const response = await api.put(`/api/v1/passport/parts/${id}`, item)

        if (response.status === 200) {
            dispatch(getSection())
        }
    } catch (e) {
        console.error(e)
    }
}