import { toast } from "react-toastify";
import api from "../../services/api";

import {
    SET_CLASS,
    SET_GEN,
    SET_GEN_TYPE,
    SET_MACHINES, SET_MACHINE_INFO, SET_MANAGER, SET_MODAL, SET_MODELS, SET_PERIOD, SET_SELLERS, SET_TYPE, SET_WORRANTY, SET_WORRANTYS
} from "./types";


export const setMachines = data => {
    return {
        type: SET_MACHINES,
        payload: data
    }
}

export const getMachines = (page = 1, type = 0) => async (dispatch, getState) => {
    try {
        const typeqery = type !== 0 ? `&type=${type}` : ''
        const response = await api.get(`/api/v1/machines?limit=40&page=${page}${typeqery}`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setMachines(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export const setType = data => {
    return {
        type: SET_TYPE,
        payload: data
    }
}

export const getType = () => async (dispatch, getState) => {
    try {
        const response = await api.get('/api/v1/types')

        if (response.status === 200) {
            const json = await response.data
            dispatch(setType(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setClass = data => {
    return {
        type: SET_CLASS,
        payload: data
    }
}

export const getClass = () => async (dispatch, getState) => {
    try {
        const response = await api.get('/api/v1/classes')

        if (response.status === 200) {
            const json = await response.data
            dispatch(setClass(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setModal = data => {
    return {
        type: SET_MODAL,
        payload: data
    }
}

export const getModal = () => async (dispatch, getState) => {
    try {
        const response = await api.get('/api/v1/models')

        if (response.status === 200) {
            const json = await response.data
            dispatch(setModal(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setGen = data => {
    return {
        type: SET_GEN,
        payload: data
    }
}

export const getGen = () => async (dispatch, getState) => {
    try {
        const response = await api.get('/api/v1/gen')

        if (response.status === 200) {
            const json = await response.data
            dispatch(setGen(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setGenType = data => {
    return {
        type: SET_GEN_TYPE,
        payload: data
    }
}

export const getModel = (id) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/classes/${id}`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(getGenType(json.type_id))
        }
    } catch (e) {
        console.error(e)
    }
}

export const getGenType = (id) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/gen?type=${id}`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setGenType(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setWorrantys = data => {
    return {
        type: SET_WORRANTYS,
        payload: data
    }
}

export const getWorrantys = () => async (dispatch, getState) => {
    try {
        const response = await api.get('/api/v1/warranty')

        if (response.status === 200) {
            const json = await response.data
            dispatch(setWorrantys(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setPeriod = data => {
    return {
        type: SET_PERIOD,
        payload: data
    }
}

export const getPeriod = () => async (dispatch, getState) => {
    try {
        const response = await api.get('/api/v1/period')

        if (response.status === 200) {
            const json = await response.data
            dispatch(setPeriod(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setModels = data => {
    return {
        type: SET_MODELS,
        payload: data
    }
}

export const getModels = (id) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/models?classes_id=${id}`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setModels(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const addModel = (item) => async (dispatch, getState) => {
    try {
        const response = await api.post('/api/v1/models', item)

        if (response.status === 201) {
            dispatch(getModels(item.classes_id))
        }
    } catch (e) {
        console.error(e)
    }
}

export const deleteModel = (classes_id, id) => async (dispatch, getState) => {
    try {
        const response = await api.delete(`/api/v1/models/${id}`)

        if (response.status === 200) {
            dispatch(getModels(classes_id))
        }
    } catch (e) {
        console.error(e)
    }
}

export const depModel = (classes_id, id, status) => async (dispatch, getState) => {
    try {
        const response = await api.put(`/api/v1/models/${id}`, {
            deprecated: status
        })

        if (response.status === 200) {
            dispatch(getModels(classes_id))
        }
    } catch (e) {
        console.error(e)
    }
}

export const addMachine = (item) => async (dispatch, getState) => {
    try {
        const response = await api.post('/api/v1/machines', item)

        if (response.status === 201) {
            dispatch(getMachines())
            toast.success('Машина добавлена')
        }
    } catch (e) {
        console.error(e)
        toast.error('Не удалось добавить машину')
    }
}

export const addType = (item) => async (dispatch, getState) => {
    try {
        const response = await api.post('/api/v1/types', item)

        if (response.status === 201) {
            dispatch(getType())
        }
    } catch (e) {
        console.error(e)
    }
}

export const addClass = (item) => async (dispatch, getState) => {
    try {
        const response = await api.post('/api/v1/classes', item)

        if (response.status === 201) {
            dispatch(getClass())
        }
    } catch (e) {
        console.error(e)
    }
}

export const deleteClass = (item) => async (dispatch, getState) => {
    try {
        const response = await api.delete(`/api/v1/classes/${item}`)

        if (response.status === 200) {
            dispatch(getClass())
        }
    } catch (e) {
        toast.error('Не удалось удалить класс')
        console.error(e)
    }
}

export const deleteType = (item) => async (dispatch, getState) => {
    try {
        const response = await api.delete(`/api/v1/types/${item}`)

        if (response.status === 200) {
            dispatch(getType())
        }
    } catch (e) {
        toast.error('Не удалось удалить тип машины')
        console.error(e)
    }
}

export const deleteGen = (item) => async (dispatch, getState) => {
    try {
        const response = await api.delete(`/api/v1/gen/${item}`)

        if (response.status === 200) {
            dispatch(getGen())
        }
    } catch (e) {
        toast.error('Не удалось удалить поколение')
        console.error(e)
    }
}

export const depGen = (item, status) => async (dispatch, getState) => {
    try {
        const response = await api.put(`/api/v1/gen/${item}`, {
            deprecated: status
        })

        if (response.status === 200) {
            dispatch(getGen())
        }
    } catch (e) {
        toast.error('Не обновить запись')
        console.error(e)
    }
}

export const addGen = (item) => async (dispatch, getState) => {
    try {
        const response = await api.post('/api/v1/gen', item)

        if (response.status === 201) {
            dispatch(getGen())
        }
    } catch (e) {
        console.error(e)
    }
}

export const setMachineInfo = data => {
    return {
        type: SET_MACHINE_INFO,
        payload: data
    }
}

export const getMachineInfo = (nomer) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/machines/${nomer}/params`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setMachineInfo(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setSeller = data => {
    return {
        type: SET_SELLERS,
        payload: data
    }
}

export const getSeller = () => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/company?type=2`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setSeller(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const setManager = data => {
    return {
        type: SET_MANAGER,
        payload: data
    }
}

export const getManager = () => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/company/16/users?role=10`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setManager(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const editMachine = (nomer, item) => async (dispatch, getState) => {
    try {
        const response = await api.put(`/api/v1/machines/${nomer}`, item)

        if (response.status === 200) {
            dispatch(getMachineInfo(nomer))
        }
    } catch (e) {
        console.error(e)
    }
}

export const serchMachine = (nomer, navigate) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/machines/${nomer}`)

        if (response.status === 200) {
            const json = await response.data

            if (typeof json.nomer !== "undefined") {
                navigate(`/passport/${nomer}`)
            } else {
                toast.error('Машина не найдена')
            }
        }

    } catch (e) {
        console.error(e)
    }
}

export const addWarranty = (item) => async (dispatch, getState) => {
    try {
        const response = await api.post('/api/v1/warranty', item)

        if (response.status === 201) {
            dispatch(getWorrantys())
        }
    } catch (e) {
        console.error(e)
    }
}

export const deleteWarranty = (item) => async (dispatch, getState) => {
    try {
        const response = await api.delete(`/api/v1/warranty/${item}`)

        if (response.status === 200) {
            dispatch(getWorrantys())
        }
    } catch (e) {
        toast.error('Не удалось удалить гарантию')
        console.error(e)
    }
}

export const setWarranty = data => {
    return {
        type: SET_WORRANTY,
        payload: data
    }
}

export const getWarranty = (id) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/warranty/${id}`)

        if (response.status === 200) {
            const json = await response.data
            dispatch(setWarranty(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const editWarranty = (id, item) => async (dispatch, getState) => {
    try {
        const response = await api.put(`/api/v1/warranty/${id}`, item)

        if (response.status === 200) {
            dispatch(getWorrantys())
        }
    } catch (e) {
        console.error(e)
    }
}