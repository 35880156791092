import api from "../../services/api";

import {
    SET_AUTH,
    SET_AUTH_EMAIL,
    SET_AUTH_PASSWORD,
    SET_EXPRES_IN,
    SET_TOKEN,
    SET_USER
} from "./types";


export const setEmail = data => {
    return {
        type: SET_AUTH_EMAIL,
        payload: data
    }
}

export const setPassword = data => {
    return {
        type: SET_AUTH_PASSWORD,
        payload: data
    }
}

export const setToken = data => {
    return {
        type: SET_TOKEN,
        payload: data
    }
}

export const setExpires_in = data => {
    return {
        type: SET_EXPRES_IN,
        payload: data
    }
}

export const setAuth = data => {
    return {
        type: SET_AUTH,
        payload: data
    }
}

export const setUser = data => {
    return {
        type: SET_USER,
        payload: data
    }
}

export const authUser = () => async (dispatch, getState) => {
    const email = getState().AuthReducer.email
    const password = getState().AuthReducer.password

    try {
        const response = await api.post('/api/auth/login', {
            email: email,
            password: password
        })

        if (response.status === 200) {
            const { access_token, expires_in } = await response.data

            localStorage.setItem("token", access_token)

            dispatch(setToken(access_token))
            dispatch(setAuth(true))
        }

    } catch (e) {
        console.error(e)
    }
}

export const logOut = () => async (dispatch, getState) => {
    try {
        const response = await api.post('/api/auth/logout', {})

        if (response.status === 200) {
            localStorage.setItem("token", '')

            dispatch(setToken(''))
            dispatch(setAuth(false))
        }

    } catch (e) {
        console.error(e)
    }
}

export const getUser = () => async (dispatch, getState) => {
    try {
        const response = await api.get('/api/user', {})

        if (response.status === 200) {
            const json = await response.data

            dispatch(setUser(json))
            dispatch(setAuth(true))
        }

    } catch (e) {
        console.error(e)
    }
}

let refreshTokenRequest = null
export const getAccessToken = () => async (dispatch, getState) => {
    try {
        const accessToken = getState().AuthReducer.token

        if (!accessToken || isTokenExpired(accessToken)) {
            if (refreshTokenRequest === null) {
                refreshTokenRequest = api.post('/api/auth/refresh-tokens', [], {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
            }

            const res = await refreshTokenRequest
            refreshTokenRequest = null

            dispatch(setToken(res.data.access_token))
            localStorage.setItem("token", res.data.access_token)


            return res.data.access_token
        }

        return accessToken
    } catch (e) {
        console.error(e)
        dispatch(setAuth(false))
        return null
    }
}

export const isTokenExpired = (token) => {
    if (!token) {
        return true
    }

    try {
        const tokenInfo = token.split('.')[1]
        const tokenInfoDecoded = window.atob(tokenInfo)
        const { exp, iat } = JSON.parse(tokenInfoDecoded)


        const tokenLeftTime = exp - getUnixTime()


        return tokenLeftTime < 300
    } catch (e) {
        console.error(e)
        return true
    }
}

export const getUnixTime = () => Math.round(+ new Date() / 1000)