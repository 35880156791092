import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editMachine,
  getClass,
  getGen,
  getMachineInfo,
  getManager,
  getModal,
  getPeriod,
  getSeller,
  getType,
  getWorrantys,
  setMachineInfo,
} from "../../store/Machine/actions";
import * as MachineSelector from "../../store/Machine/selectors";
import moment from "moment";
import AddForm from "./AddForm";
import MoveDevise from "./MoveDevise";
import EditImai from "./EditImai";
import EditFrameware from "./EditFrameware";

function MachineInfo({ nomer }) {
  const dispatch = useDispatch();

  const machine_info = useSelector(MachineSelector.machine_info);
  const modal = useSelector(MachineSelector.modal);
  const gen = useSelector(MachineSelector.gen);
  const worrantys = useSelector(MachineSelector.worrantys);
  const sellers = useSelector(MachineSelector.sellers);
  const manager = useSelector(MachineSelector.manager);
  const period = useSelector(MachineSelector.period);

  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [openMoveDevise, setOpenMoveDevise] = useState(false);
  const [openEditImai, setOpenEditImai] = useState(false);
  const [openEditFr, setOpenEditFr] = useState(false);

  const [modalValue, setModalValue] = useState(0);
  const [modalRealValue, setModalRealValue] = useState(0);
  const [genValue, setGenValue] = useState(0);
  const [warrantyValue, setWarrantyValue] = useState(0);
  const [sellerValue, setSellerValue] = useState(0);
  const [managerValue, setManagerValue] = useState(0);
  const [nomer_1cValue, setNomer_1cValue] = useState(0);
  const [complectationValue, setComplectationValue] = useState(0);
  const [dateContractValue, setDateContractValue] = useState(0);
  const [dateShipmentValue, setDateShipmentValue] = useState(0);
  const [dateStartworkValue, setDateStartworkValue] = useState(0);
  const [warrantyStatusValue, setWarrantyStatusValue] = useState(0);
  const [militaryValue, setMilitaryValue] = useState(0);
  const [periodValue, setPeriodValue] = useState(0);
  const [commentValue, setCommentValue] = useState("");

  useEffect(() => {
    dispatch(getMachineInfo(nomer));
    dispatch(getModal());
    dispatch(getGen());
    dispatch(getWorrantys());
    dispatch(getPeriod());
    dispatch(getSeller());
    dispatch(getManager());

    return () => {
      dispatch(setMachineInfo([]));
    };
  }, []);

  useEffect(() => {
    if (edit) {
      setModalValue(machine_info.model_id);
      setModalRealValue(machine_info.real_model_id);
      setGenValue(machine_info.gen_id);
      setSellerValue(machine_info.seller);
      if (machine_info.warranty !== null)
        setWarrantyValue(machine_info.warranty.id);

      if (machine_info.manager !== null) setManagerValue(machine_info.manager);

      setNomer_1cValue(machine_info.nomer_1c);
      setComplectationValue(machine_info.complectation);
      setDateContractValue(machine_info.date_contract);
      setDateShipmentValue(machine_info.date_shipment);
      setDateStartworkValue(machine_info.date_startwork);
      setWarrantyStatusValue(machine_info.warranty_status);
      setMilitaryValue(machine_info.military);
      setPeriodValue(machine_info.period_to);
      if (machine_info.comment !== null) setCommentValue(machine_info.comment);
      console.log("test", commentValue);
      console.log(machine_info.comment, commentValue);
    }
  }, [edit]);

  const handleUpdateMachine = () => {
    const data = {
      nomer_1c: nomer_1cValue,
      model: modalValue,
      real_model_id: modalRealValue,
      complectation: complectationValue,
      generation: genValue,
      warranty: warrantyValue,
      warranty_status: warrantyStatusValue,
      period_to: periodValue,
      comment: commentValue,
      military: militaryValue,
      seller: sellerValue,
      manager: managerValue,
      date_contract: dateContractValue,
      date_shipment: dateShipmentValue,
      date_startwork: dateStartworkValue,
    };

    dispatch(editMachine(nomer, data));
    setEdit(false);
  };

  return (
    <Box>
      {machine_info.length !== 0 ? (
        <>
          {!machine_info.imei ? (
            <Button
              sx={{
                width: "100%",
                height: "60px",
                mb: "24px",
              }}
              color="error"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              Добавить IMEI
            </Button>
          ) : (
            <Box
              sx={{
                mb: "24px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "60px",
                  backgroundColor: "#219653",
                  color: "#fff",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: "24px",
                }}
              >
                {machine_info.imei}
              </Box>
              <Button
                color="custom_purple"
                variant="contained"
                style={{
                  marginRight: "10px",
                }}
                onClick={() => setOpenMoveDevise(true)}
              >
                Заменить устройство
              </Button>
              {/* <Button
                color="custom_purple"
                variant="contained"
                style={{
                  marginRight: "10px",
                }}
                onClick={() => setOpenEditImai(true)}
              >
                Изменить IMEI
              </Button> */}
              <Button
                color="custom_purple"
                variant="contained"
                style={{
                  marginRight: "10px",
                }}
                onClick={() => setOpenEditFr(true)}
              >
                Изменить тип прошивки
              </Button>
            </Box>
          )}
          <TableContainer>
            <Table
              sx={{
                width: "100%",
                "& .MuiTableBody-root": {
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                },
              }}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Номер
                  </TableCell>
                  <TableCell align="right">{machine_info.nomer}</TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Номер 1с
                  </TableCell>
                  <TableCell align="right">
                    {!edit ? (
                      machine_info.nomer_1c
                    ) : (
                      <TextField
                        type="text"
                        variant="outlined"
                        value={nomer_1cValue}
                        onChange={(e) => setNomer_1cValue(e.target.value)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Тип
                  </TableCell>
                  <TableCell align="right">{machine_info.type}</TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Модель
                  </TableCell>
                  <TableCell align="right">
                    {!edit ? (
                      machine_info.model
                    ) : (
                      <TextField
                        margin="dense"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                        value={modalValue}
                        onChange={(e) =>
                          setModalValue(parseInt(e.target.value))
                        }
                      >
                        <option value={0}>Выбрать...</option>
                        {modal.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.title}
                          </option>
                        ))}
                      </TextField>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Модель Реальная
                  </TableCell>
                  <TableCell align="right">
                    {!edit ? (
                      machine_info.real_modal
                    ) : (
                      <TextField
                        margin="dense"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                        value={modalRealValue}
                        onChange={(e) =>
                          setModalRealValue(parseInt(e.target.value))
                        }
                      >
                        <option value={0}>Выбрать...</option>
                        {modal.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.title}
                          </option>
                        ))}
                      </TextField>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Класс
                  </TableCell>
                  <TableCell align="right">{machine_info.classes}</TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Комплектация
                  </TableCell>
                  <TableCell align="right">
                    {!edit ? (
                      machine_info.complectation
                    ) : (
                      <TextField
                        type="text"
                        variant="outlined"
                        value={complectationValue}
                        onChange={(e) => setComplectationValue(e.target.value)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Поколение
                  </TableCell>
                  <TableCell align="right">
                    {!edit ? (
                      machine_info.generation
                    ) : (
                      <TextField
                        margin="dense"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                        value={genValue}
                        onChange={(e) => setGenValue(parseInt(e.target.value))}
                      >
                        <option value={0}>Выбрать...</option>
                        {gen.map((option) => {
                          if (option.type_id === machine_info.type_id)
                            return (
                              <option key={option.id} value={option.id}>
                                {option.title}
                              </option>
                            );
                        })}
                      </TextField>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Период ТО
                  </TableCell>
                  <TableCell align="right">
                    {!edit ? (
                      machine_info.period_to !== null ? (
                        period.find(
                          (item) => item.id === machine_info.period_to
                        ).hours
                      ) : (
                        "Отсутствует"
                      )
                    ) : (
                      <TextField
                        margin="dense"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                        value={periodValue}
                        onChange={(e) =>
                          setPeriodValue(parseInt(e.target.value))
                        }
                      >
                        <option value={0}>Выбрать...</option>
                        {period.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.hours}
                          </option>
                        ))}
                      </TextField>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Комментарий
                  </TableCell>
                  <TableCell align="right">
                    {!edit ? (
                      machine_info.comment !== null ? (
                        machine_info.comment
                      ) : (
                        "-"
                      )
                    ) : (
                      <TextField
                        margin="dense"
                        value={commentValue}
                        multiline
                        rows={3}
                        type="text"
                        variant="outlined"
                        maxRows={3}
                        sx={{ width: 300 }}
                        onChange={(e) => setCommentValue(e.target.value)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Гарантия
                  </TableCell>
                  <TableCell align="right">
                    {!edit ? (
                      machine_info.warranty !== null ? (
                        machine_info.warranty.title
                      ) : (
                        "Отсутствует"
                      )
                    ) : (
                      <TextField
                        margin="dense"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                        value={warrantyValue}
                        onChange={(e) =>
                          setWarrantyValue(parseInt(e.target.value))
                        }
                      >
                        <option value={0}>Выбрать...</option>
                        {worrantys.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.title}
                          </option>
                        ))}
                      </TextField>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Активность гарантии
                  </TableCell>
                  <TableCell align="right">
                    {!edit ? (
                      machine_info.warranty_status ? (
                        "На гарантии"
                      ) : (
                        "Отсутствует"
                      )
                    ) : (
                      <Checkbox
                        checked={warrantyStatusValue}
                        onChange={(e) =>
                          setWarrantyStatusValue(!warrantyStatusValue)
                        }
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Военная
                  </TableCell>
                  <TableCell align="right">
                    {!edit ? (
                      machine_info.military ? (
                        "Да"
                      ) : (
                        "Нет"
                      )
                    ) : (
                      <Checkbox
                        checked={militaryValue}
                        onChange={(e) => setMilitaryValue(!militaryValue)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Дата заключения контракта
                  </TableCell>
                  <TableCell align="right">
                    {!edit ? (
                      machine_info.date_contract !== null ? (
                        moment(machine_info.date_contract).format("DD.MM.YYYY")
                      ) : (
                        "Нет данных"
                      )
                    ) : (
                      <TextField
                        inputProps={{
                          type: "date",
                        }}
                        type="text"
                        variant="outlined"
                        value={dateContractValue}
                        onChange={(e) => setDateContractValue(e.target.value)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Дата отгрузки
                  </TableCell>
                  <TableCell align="right">
                    {!edit ? (
                      machine_info.date_shipment !== null ? (
                        moment(machine_info.date_shipment).format("DD.MM.YYYY")
                      ) : (
                        "Нет данных"
                      )
                    ) : (
                      <TextField
                        inputProps={{
                          type: "date",
                        }}
                        type="text"
                        variant="outlined"
                        value={dateShipmentValue}
                        onChange={(e) => setDateShipmentValue(e.target.value)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Дата начала работы
                  </TableCell>
                  <TableCell align="right">
                    {!edit ? (
                      machine_info.date_startwork !== null ? (
                        moment(machine_info.date_startwork).format("DD.MM.YYYY")
                      ) : (
                        "Нет данных"
                      )
                    ) : (
                      <TextField
                        inputProps={{
                          type: "date",
                        }}
                        type="text"
                        variant="outlined"
                        value={dateStartworkValue}
                        onChange={(e) => setDateStartworkValue(e.target.value)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Продавец
                  </TableCell>
                  <TableCell align="right">
                    {!edit ? (
                      machine_info.seller !== null ? (
                        machine_info.seller_info.title
                      ) : (
                        "Нет данных"
                      )
                    ) : (
                      <TextField
                        margin="dense"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                        value={sellerValue}
                        onChange={(e) =>
                          setSellerValue(parseInt(e.target.value))
                        }
                      >
                        <option value={0}>Выбрать...</option>
                        {sellers.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.title}
                          </option>
                        ))}
                      </TextField>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Менеджер
                  </TableCell>
                  <TableCell align="right">
                    {!edit ? (
                      machine_info.manager !== 0 &&
                      machine_info.manager !== null ? (
                        machine_info.manager_info.name
                      ) : (
                        "Нет данных"
                      )
                    ) : (
                      <TextField
                        margin="dense"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                        value={managerValue}
                        onChange={(e) =>
                          setManagerValue(parseInt(e.target.value))
                        }
                      >
                        <option value={0}>Выбрать...</option>
                        {manager.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Владелец
                  </TableCell>
                  <TableCell align="right">
                    {typeof machine_info.owner !== "undefined"
                      ? machine_info.owner_info.title
                      : "Нет данных"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {!edit ? (
              <Button
                sx={{
                  mt: "25px",
                }}
                variant="contained"
                color="custom_purple"
                onClick={() => setEdit(true)}
              >
                Редактировать
              </Button>
            ) : (
              <>
                <Button
                  sx={{
                    mt: "24px",
                  }}
                  variant="contained"
                  color="error"
                  onClick={() => setEdit(false)}
                >
                  отмена
                </Button>
                <Button
                  sx={{
                    mt: "24px",
                    ml: "24px",
                  }}
                  variant="contained"
                  color="custom_purple"
                  onClick={handleUpdateMachine}
                >
                  Сохранить
                </Button>
              </>
            )}
          </TableContainer>
        </>
      ) : null}
      <AddForm open={open} setOpen={setOpen} />
      <MoveDevise open={openMoveDevise} setOpen={setOpenMoveDevise} />
      <EditImai open={openEditImai} setOpen={setOpenEditImai} />
      <EditFrameware open={openEditFr} setOpen={setOpenEditFr} />
    </Box>
  );
}

export default MachineInfo;
