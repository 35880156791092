import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, IconButton, Switch, TextField } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { addClass } from '../../store/Machine/actions';
import { setSection } from '../../store/Passport/actions';
import * as PassportSelector from "../../store/Passport/selectors"

function AddSectionForm({ open, setOpen, id }) {
    const dispatch = useDispatch()

    const template = useSelector(PassportSelector.template)

    const [title, setTitle] = useState('');
    const [sort, setSort] = useState('');
    const [type, setType] = useState("string");
    const [comment, setComment] = useState('');

    const [multy, setMulty] = useState(false);
    const [parent, setParent] = useState(0);

    const [typeError, setTypeError] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSend = (e) => {
        e.preventDefault()

        if (type === null) {
            setTypeError(true)
            return false
        }

        dispatch(setSection({
            "title": title,
            "parent": id,
            "sort": sort,
            "type": type,
            "comment": comment,
            "multiply": multy,
            "parent_id": parent
        }))

        setOpen(false)
        setTitle('')
        setSort('')
        setTypeError(false)
        setComment('')
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <form
                onSubmit={(e) => handleSend(e)}
                autoComplete="off"
            >
                <DialogTitle>
                    Добавить раздел
                    <IconButton
                        aria-label="close"
                        onClick={() => handleClose(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="nomer"
                            label="Раздел"
                            type="text"
                            fullWidth
                            variant="outlined"
                            required={true}
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            id="sort"
                            label="Сортировка"
                            type="number"
                            fullWidth
                            variant="outlined"
                            required={true}
                            value={sort}
                            onChange={e => setSort(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            select
                            label="Тип"
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            required={true}
                            value={type}
                            error={typeError}
                            onChange={e => setType(e.target.value)}
                        >
                            <option value={"string"}>Строка</option>
                            <option value={"int"}>Список</option>
                        </TextField>
                        {
                            template.length !== 0 ? (
                                type === "string" ? (
                                    <TextField
                                        margin="dense"
                                        select
                                        label="Привязать"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        variant="outlined"
                                        value={parent}
                                        onChange={e => setParent(e.target.value)}
                                    >
                                        <option value={0}>Выбрать</option>
                                        {
                                            template.map((itemSection, k) => {
                                                if (itemSection.parent === id) {
                                                    if (itemSection.type === 'int') {
                                                        return <option key={itemSection.id} value={itemSection.id}>{itemSection.title} {itemSection.comment}</option>
                                                    }
                                                }
                                            })
                                        }
                                    </TextField>
                                ) : null
                            ) : null
                        }
                        <TextField
                            margin="dense"
                            label="Комментарий"
                            multiline
                            maxRows={4}
                            value={comment}
                            onChange={e => setComment(e.target.value)}
                            variant="outlined"
                        />
                        {
                            type === "int" ? (
                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={multy} onChange={() => setMulty(!multy)} />} label="Множественный" />
                                </FormGroup>
                            ) : null
                        }
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Добавить</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddSectionForm