import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, RadioGroup, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import * as DeviceSelectors from "../../store/Device/selectors"
import { addDevice, getDeviceType, setDeviceType } from '../../store/Device/actions';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';

function Info({ open, setOpen }) {
    const dispatch = useDispatch()
    const types = useSelector(DeviceSelectors.types)

    const info = useSelector(DeviceSelectors.info)

    useEffect(() => {
        dispatch(getDeviceType())

        return () => {
            dispatch(setDeviceType([]))
        }
    }, [])

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <DialogTitle>
                Информация
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Номер</TableCell>
                                <TableCell align="right">{info.sn}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Тип машины</TableCell>
                                <TableCell align="right">{(types.length !== 0 && typeof info.type !== "undefined") && types.find(item => item.type === info.type).name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>imei</TableCell>
                                <TableCell align="right">{info.imei}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Статус</TableCell>
                                <TableCell align="right">{info.status ? <CircleIcon sx={{ color: "#219653" }} /> : <CircleIcon sx={{ color: "#EB5757" }} />}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Отслеживание</TableCell>
                                <TableCell align="right">{info.trackable ? <CircleIcon sx={{ color: "#219653" }} /> : <CircleIcon sx={{ color: "#EB5757" }} />}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Окончание лицензии</TableCell>
                                <TableCell align="right">{moment(info.expDate).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>
    )
}

export default Info