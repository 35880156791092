import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Collapse, Fab, FormControlLabel, IconButton, List, ListItem, ListItemText, Switch, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/DeleteForeverTwoTone'
import AddBoxIcon from '@mui/icons-material/AddBoxTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import * as PassportSelector from "../../store/Passport/selectors"
import { deleteLink, deleteSection, getItems, getSection, setItems, setSections, updateElement, updateSubSection } from '../../store/Passport/actions'
import AddIcon from '@mui/icons-material/Add'
import AddForm from './AddForm';
import AddSectionForm from './AddSectionForm';
import AddItem from './AddItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import Addlink from './Addlink';

function PassportTemplate() {
    const dispatch = useDispatch()

    const template = useSelector(PassportSelector.template)
    const items = useSelector(PassportSelector.items)

    const [open, setOpen] = useState(false)
    const [openSection, setOpenSection] = useState(false)
    const [openItem, setOpenItem] = useState(false)
    const [openLink, setOpenLink] = useState(false)

    const [expanded, setExpanded] = useState(false);
    const [subExpanded, setSubExpanded] = useState(false);

    const [id, setId] = useState()
    const [section_id, setSectionId] = useState()

    useEffect(() => {
        dispatch(getSection())

        return () => {
            dispatch(setSections([]))
        }
    }, [])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSubChange = (panel, id) => (event, isExpanded) => {
        event.stopPropagation()
        if (subExpanded === panel) {
            dispatch(setItems([]))
        } else {
            dispatch(getItems(id))
        }
        setSubExpanded(isExpanded ? panel : false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'block', width: "100%" }}>
                {
                    template.map((item, i) => {
                        if (item.parent === null)
                            return (
                                <Accordion key={item.id} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                            {item.sort}. {item.title}
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary' }}>{item.comment}</Typography>

                                        <IconButton sx={{ marginLeft: "auto", marginRight: "10px" }} edge="end" aria-label="delete" onClick={(e) => {
                                            e.stopPropagation()
                                            dispatch(deleteSection(item.id))
                                        }}>
                                            <DeleteIcon sx={{
                                                width: '20px',
                                                height: '20px',
                                                color: "#f60607",
                                            }} />
                                        </IconButton>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Collapse in={true} timeout="auto" unmountOnExit>
                                            {
                                                template.map((itemSection, k) => {
                                                    if (itemSection.parent === item.id) {
                                                        if (itemSection.type === 'int') {
                                                            return (
                                                                <Accordion key={itemSection.id} expanded={subExpanded === `panel${k}`} onChange={handleSubChange(`panel${k}`, itemSection.id)}>
                                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{
                                                                        ":hover": {
                                                                            backgroundColor: "#0000000a"
                                                                        }
                                                                    }}>
                                                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                                            {itemSection.sort}. {itemSection.title} {itemSection.multiply ? '— Множественный' : ''}
                                                                        </Typography>
                                                                        <Typography sx={{ color: 'text.secondary' }}>{itemSection.comment}</Typography>
                                                                        <FormControlLabel onClick={(e) => e.stopPropagation()} control={<Switch checked={itemSection.multiply ? true : false} onChange={(e) => {
                                                                            e.stopPropagation()
                                                                            dispatch(updateSubSection({
                                                                                'multiply': !itemSection.multiply === true ? 1 : 0
                                                                            }, itemSection.id))
                                                                        }} />} label="Множественный" />
                                                                        <IconButton sx={{ marginLeft: "auto", marginRight: "10px" }} edge="end" aria-label="delete" onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            dispatch(deleteSection(itemSection.id))
                                                                        }}>
                                                                            <DeleteIcon sx={{
                                                                                width: '20px',
                                                                                height: '20px',
                                                                                color: "#f60607",
                                                                            }} />
                                                                        </IconButton>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <List dense={true}>
                                                                            {
                                                                                items.map(itemElem => (
                                                                                    <ListItem
                                                                                        key={itemElem.id}
                                                                                        secondaryAction={
                                                                                            <IconButton edge="end" aria-label="delete"
                                                                                                onClick={() => dispatch(deleteLink({
                                                                                                    id: itemElem.id_link,
                                                                                                    part_id: itemSection.id
                                                                                                }))}
                                                                                            >
                                                                                                <DeleteIcon sx={{
                                                                                                    width: '20px',
                                                                                                    height: '20px',
                                                                                                    color: "#f60607",
                                                                                                }} />
                                                                                            </IconButton>
                                                                                        }
                                                                                    >
                                                                                        <ListItemText primary={itemElem.title} secondary={itemElem.code} />
                                                                                    </ListItem>
                                                                                ))
                                                                            }
                                                                        </List>
                                                                        <Button onClick={() => {
                                                                            setOpenItem(true)
                                                                            setId(itemSection.id)
                                                                        }} color='custom_purple' sx={{ marginTop: '15px' }} variant="contained" startIcon={<AddBoxIcon />}>Добавить элемент</Button>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            )
                                                        } else {
                                                            return (
                                                                <ListItem
                                                                    sx={{
                                                                        ":hover": {
                                                                            backgroundColor: "#0000000a"
                                                                        }
                                                                    }}
                                                                    secondaryAction={
                                                                        <>
                                                                            <IconButton edge="end" aria-label="link" onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                if (itemSection.parent_id === null) {
                                                                                    setOpenLink(true)
                                                                                    setId(itemSection.id)
                                                                                    setSectionId(itemSection.parent)
                                                                                } else {
                                                                                    dispatch(updateSubSection({
                                                                                        'parent_id': 0
                                                                                    }, itemSection.id))
                                                                                }
                                                                            }}>
                                                                                <InsertLinkIcon sx={{
                                                                                    width: '20px',
                                                                                    height: '20px',
                                                                                    color: itemSection.parent_id !== null ? "#5955b3" : "#54607aad",
                                                                                }} />
                                                                            </IconButton>
                                                                            <IconButton edge="end" aria-label="delete"
                                                                                sx={{
                                                                                    marginLeft: "15px"
                                                                                }}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation()
                                                                                    dispatch(deleteSection(itemSection.id))
                                                                                }}>
                                                                                <DeleteIcon sx={{
                                                                                    width: '20px',
                                                                                    height: '20px',
                                                                                    color: "#f60607",
                                                                                }} />
                                                                            </IconButton>
                                                                        </>
                                                                    }
                                                                    key={itemSection.id}>
                                                                    <ListItemText primary={`${itemSection.sort}. ${itemSection.title}`} secondary={itemSection.comment} />
                                                                </ListItem>
                                                            )
                                                        }
                                                    }
                                                })
                                            }
                                            <Button color='custom_purple' onClick={() => {
                                                setId(item.id)
                                                setOpenSection(true)
                                            }} sx={{ marginTop: '15px' }} variant="contained" startIcon={<AddBoxIcon />}>Добавить раздел</Button>
                                        </Collapse>
                                    </AccordionDetails>
                                </Accordion>
                            )
                    })
                }
                <Fab color='custom_purple' aria-label="add" sx={{
                    position: 'fixed',
                    bottom: '50px',
                    right: '50px'
                }}
                    onClick={() => setOpen(true)}>
                    <AddIcon />
                </Fab>
            </Box>
            <AddForm open={open} setOpen={setOpen} />
            <AddSectionForm open={openSection} setOpen={setOpenSection} id={id} />
            <AddItem open={openItem} setOpen={setOpenItem} id={id} />
            <Addlink open={openLink} setOpen={setOpenLink} id={id} section={section_id} />
        </Box>
    )
}

export default PassportTemplate