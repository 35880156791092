import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, List, ListItem, ListItemText, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { addModel, deleteModel, depModel, getModels } from '../../store/Machine/actions';
import * as MachineSelector from "../../store/Machine/selectors"
import DeleteIcon from '@mui/icons-material/DeleteForeverTwoTone'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

function AddModel({ open, setOpen, id }) {
    const dispatch = useDispatch()

    const models = useSelector(MachineSelector.models)

    const [title, setTitle] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (typeof id !== "undefined")
            dispatch(getModels(id))
    }, [id])

    const handleSend = (e) => {
        e.preventDefault()

        dispatch(addModel({
            "classes_id": id,
            "title": title
        }))

        setTitle('')
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <form
                onSubmit={(e) => handleSend(e)}
                autoComplete="off"
            >
                <DialogTitle>
                    Добавить модель
                    <IconButton
                        aria-label="close"
                        onClick={() => handleClose(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <List dense={true}>
                        {
                            models.map(model => {
                                return (
                                    <ListItem
                                        key={model.id}
                                        secondaryAction={
                                            <>
                                                <IconButton edge="end" aria-label="delete" sx={{
                                                    mr: '5px'
                                                }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        dispatch(depModel(id, model.id, !model.deprecated))
                                                    }}>
                                                    {
                                                        !model.deprecated ? <VisibilityOffIcon sx={{
                                                            width: '20px',
                                                            height: '20px',
                                                            color: '#a4a4a4'
                                                        }} /> : <VisibilityIcon sx={{
                                                            width: '20px',
                                                            height: '20px',
                                                            color: '#a4a4a4'
                                                        }} />
                                                    }
                                                </IconButton>
                                                <IconButton edge="end" aria-label="delete"
                                                    onClick={() => dispatch(deleteModel(id, model.id))}
                                                >
                                                    <DeleteIcon sx={{
                                                        width: '20px',
                                                        height: '20px',
                                                        color: "#f60607",
                                                    }} />
                                                </IconButton>
                                            </>
                                        }
                                    >
                                        <ListItemText primary={model.title} />
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                    <FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="nomer"
                            label="Модель"
                            type="text"
                            fullWidth
                            variant="outlined"
                            required={true}
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Добавить</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddModel