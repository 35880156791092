import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, RadioGroup, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import * as DeviceSelectors from "../../store/Device/selectors"
import { addDevice, getDeviceType, setDeviceType } from '../../store/Device/actions';
import * as MachineSelector from '../../store/Machine/selectors'


function AddForm({ open, setOpen }) {
    const dispatch = useDispatch()

    const [sn, setSn] = useState('');
    const [imei, setImei] = useState('');
    const [type, setType] = useState(0);

    const machine_info = useSelector(MachineSelector.machine_info)
    const types = useSelector(DeviceSelectors.types)

    useEffect(() => {
        dispatch(getDeviceType())

        return () => {
            dispatch(setDeviceType([]))
        }
    }, [])

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdate = () => {
        let body = {
            nomer: machine_info.nomer,
            imei: imei,
            type: type
        }
        dispatch(addDevice(body))

        setOpen(false)
        setImei('')
        setType(0)
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <DialogTitle>
                Добавить
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormControl>
                    <TextField
                        margin="dense"
                        label="Imei"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={imei}
                        onChange={e => setImei(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        select
                        label="Тип"
                        value={type}
                        onChange={e => setType(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                    >
                        <option value={0}>Выбрать</option>
                        {types.map((option) => (
                            <option key={option.type} value={option.type}>
                                {option.name}
                            </option>
                        ))}
                    </TextField>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleUpdate(false)}>Добавить</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddForm