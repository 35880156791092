import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, List, ListItem, ListItemText, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import * as PassportSelector from "../../store/Passport/selectors"
import { updateSubSection } from '../../store/Passport/actions';

function Addlink({ open, setOpen, id, section }) {
    const dispatch = useDispatch()

    const [item, setItem] = useState(0)

    const template = useSelector(PassportSelector.template)

    const handleClose = () => {
        setOpen(false);
    };

    const handleSend = (e) => {
        e.preventDefault()

        dispatch(updateSubSection({
            'parent_id': item
        }, id))

        setItem(0)
        setOpen(false)
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <form
                onSubmit={(e) => handleSend(e)}
                autoComplete="off"
            >
                <DialogTitle>
                    Добавить связь
                    <IconButton
                        aria-label="close"
                        onClick={() => handleClose(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <TextField
                            margin="dense"
                            select
                            label="Привязать"
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            value={item}
                            onChange={e => setItem(e.target.value)}
                        >
                            <option value={0}>Выбрать</option>
                            {
                                template.map((itemSection, k) => {
                                    if (itemSection.parent === section) {
                                        if (itemSection.type === 'int') {
                                            return <option key={itemSection.id} value={itemSection.id}>{itemSection.title} {itemSection.comment}</option>
                                        }
                                    }
                                })
                            }
                        </TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Добавить</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default Addlink