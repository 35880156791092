import { Button, Checkbox, Fab, FormControlLabel, FormLabel, Grid, Pagination, Radio, RadioGroup, TextField } from '@mui/material'
import { Box, Container } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import * as DeviceSelectors from "../../store/Device/selectors"
import { useDispatch, useSelector } from 'react-redux';
import { getDevice, getDeviceType, serchMachine, setDevice, setDeviceType } from '../../store/Device/actions';
import { Table } from '../../components';
import CircleIcon from '@mui/icons-material/Circle';
import AddForm from './AddForm';
import EditForm from './EditForm';
import Info from './Info';

function LinkImei() {
    const dispatch = useDispatch()

    const device = useSelector(DeviceSelectors.device)
    const types = useSelector(DeviceSelectors.types)

    const [open, setOpen] = useState(false)
    const [openS, setOpenS] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [item, setItem] = useState([])

    const [radio, setRadio] = useState(0)
    const [radioOld, setRadioOld] = useState(0)
    const [page, setPage] = useState(1)

    const [machine_nomer, setMachineNomer] = useState('')

    const ref = useRef()

    useEffect(() => {
        dispatch(getDevice())
        dispatch(getDeviceType())

        return () => {
            dispatch(setDevice([]))
            dispatch(setDeviceType([]))
        }
    }, [])

    useEffect(() => {
        if (radio !== radioOld) {
            setPage(1)
            dispatch(getDevice(1, radio))
        } else {
            dispatch(getDevice(page, radio))
        }
        setRadioOld(radio)
        ref.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [radio, page])

    const type = (device) => {
        const index = types.find(item => item.type === device.type)

        return index.name
    }

    const status = (item) => {
        return item.status ? <CircleIcon sx={{ color: "#219653" }} /> : <CircleIcon sx={{ color: "#EB5757" }} />
    }

    const trackable = (item) => {
        return item.trackable ? <CircleIcon sx={{ color: "#219653" }} /> : <CircleIcon sx={{ color: "#EB5757" }} />
    }

    const edit = (item) => {
        return <span onClick={() => {
            setOpenEdit(true)
            setItem(item)
        }}>{item.sn}</span>
    }

    const tabCols = [
        { title: "Номер", val: edit },
        { title: "Тип машины", val: type },
        { title: "imei", code: "imei" },
        { title: "Статус", val: status },
        { title: "Отслеживание", val: trackable },
        { title: "Окончание лицензии", code: "expDate", type: "dateTime" }
    ]

    const tabParams = {

    }

    return (
        <Grid container
            sx={{
                overflow: 'auto',
                height: 'calc(100vh - 129px)',
                margin: 0,
            }}
        >
            <Grid item xs={3} sx={{
                height: '100%'
            }}>
                <Box
                    sx={{
                        background: "#f5f6fa",
                        padding: "20px 24px",
                        marginRight: '24px',
                        borderRadius: '4px',
                        mb: 2
                    }}
                >
                    <h3
                        style={{
                            margin: "0",
                            marginBottom: "10px"
                        }}
                    >Поиск по номеру</h3>
                    <TextField
                        margin="dense"
                        id="name"
                        label="Номер"
                        type="text"
                        fullWidth
                        variant="outlined"
                        size='small'
                        value={machine_nomer}
                        onChange={e => setMachineNomer(e.target.value)}
                    />
                    <Button onClick={() => {
                        dispatch(serchMachine(machine_nomer))
                        setOpenS(true)
                    }} variant="contained" color="custom_purple">Найти</Button>
                </Box>
                <Box
                    sx={{
                        background: "#f5f6fa",
                        padding: "20px 24px",
                        marginRight: '24px',
                        borderRadius: '4px'
                    }}
                >
                    <FormLabel id="radio-buttons-group-label">Лицензия</FormLabel>
                    <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value={0} control={<Radio color='custom_purple' checked={radio === 0} onClick={() => setRadio(0)} />} label="Все" />
                        <FormControlLabel value={1} control={<Radio color='custom_purple' checked={radio === 1} onClick={() => setRadio(1)} />} label="Активна" />
                        <FormControlLabel value={2} control={<Radio color='custom_purple' checked={radio === 2} onClick={() => setRadio(2)} />} label="Не активна" />
                        <FormControlLabel value={3} control={<Radio color='custom_purple' checked={radio === 3} onClick={() => setRadio(3)} />} label="Лицензия закончится (30 дн)" />
                        <FormControlLabel value={4} control={<Radio color='custom_purple' checked={radio === 4} onClick={() => setRadio(4)} />} label="Лицензия закончилась (30 дн)" />
                    </RadioGroup>
                    <Button variant="contained" color="custom_purple">Применить</Button>
                </Box>
            </Grid>
            <Grid item xs={9} sx={{
                height: '100%'
            }}>
                <Box maxWidth="xl"
                    ref={ref}
                    sx={{
                        height: '90%',
                        overflowY: 'scroll',
                        '::-webkit-scrollbar': {
                            width: '8px'
                        },
                        '::-webkit-scrollbar-track': {
                            background: '#EEEEEE',
                            borderRadius: '10px'
                        },
                        '::-webkit-scrollbar-thumb': {
                            backgroundColor: '#5955b3',
                            borderRadius: '10px'
                        }
                    }}>
                    <Table cols={tabCols} data={typeof device.data !== "undefined" ? device.data : []} params={tabParams} rows={40} />
                    {/* <Fab color='custom_purple' aria-label="add" sx={{
                        position: 'fixed',
                        bottom: '50px',
                        right: '50px'
                    }}
                        onClick={() => setOpen(true)}
                    >
                        <AddIcon />
                    </Fab>
                    <AddForm open={open} setOpen={setOpen} />
                    <EditForm open={openEdit} setOpen={setOpenEdit} item={item} />
                     */}
                    <Info open={openS} setOpen={setOpenS} />
                </Box>
                <Pagination
                    sx={{
                        marginTop: '24px'
                    }}
                    page={page}
                    count={device.last_page}
                    shape="rounded"
                    color="custom_purple"
                    onChange={(event, page) => {
                        setPage(page)
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default LinkImei