import { value } from "./selectors";
import { SET_NEW_VALUE, SET_ITEMS, SET_PART, SET_PASSPORT_PARENT, SET_PASSPORT_SECTION, SET_SEARCH, SET_SECTIONS, SET_SECTIONS_TEAMPLATE, SET_VALUE } from "./types";

const initialState = {
    sections: [],
    template: [],
    items: [],
    search: [],
    parts: [],
    passportParent: [],
    passportSection: [],
    value: []
}

export const PassportReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SECTIONS:
            return { ...state, sections: action.payload }
        case SET_SECTIONS_TEAMPLATE:
            return { ...state, template: action.payload }
        case SET_ITEMS:
            return { ...state, items: action.payload }
        case SET_SEARCH:
            return { ...state, search: action.payload }
        case SET_PART:
            return { ...state, parts: action.payload }
        case SET_PASSPORT_PARENT:
            return { ...state, passportParent: action.payload }
        case SET_PASSPORT_SECTION:
            return { ...state, passportSection: action.payload }
        case SET_VALUE:
            const i = action.payload.i
            const id = action.payload.id
            const value = action.payload.value
            const item = action.payload.item
            const count = action.payload.count

            return {
                ...state, value: {
                    ...state.value, [i]: {
                        part: id,
                        item: item,
                        value: value,
                        count: count
                    }
                }
            }

        case SET_NEW_VALUE:
            return { ...state, value: action.payload }
        default:
            return state
    }
}