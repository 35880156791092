import { Box, Collapse, Drawer, Fab, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/DeleteForeverTwoTone'
import AddBoxIcon from '@mui/icons-material/AddBoxTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from '../../store/Machine/actions';
import * as MachineSelector from "../../store/Machine/selectors"
import * as PassportSelector from "../../store/Passport/selectors"
import { getTypesSection, deleteType } from '../../store/Passport/actions'
import AddIcon from '@mui/icons-material/Add'
import AddForm from './AddForm';

function PassportTemplateType() {
    const dispatch = useDispatch()

    const type = useSelector(MachineSelector.type)

    const sections = useSelector(PassportSelector.sections)

    const [open, setOpen] = useState(false)
    const [id, setId] = useState()

    useEffect(() => {
        dispatch(getType())
    }, [])

    useEffect(() => {
        if (type.length !== 0) {
            dispatch(getTypesSection(type[0].id))
            setId(type[0].id)
        }
    }, [type])

    const handleGetTypesSection = (id) => {
        dispatch(getTypesSection(id))
        setId(id)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'block', width: "20%" }}>
                <List sx={{ backgroundColor: "#5955b3", borderRadius: "3px" }}>
                    {
                        type.map(item => (
                            <ListItem disablePadding key={item.id}>
                                <ListItemButton onClick={() => handleGetTypesSection(item.id)}>
                                    <ListItemText primary={item.title} sx={{ color: "#b9b7ff", fontWeight: 'Light' }} />
                                </ListItemButton>
                            </ListItem>
                        ))
                    }
                </List>
            </Box>
            <Box sx={{ display: 'block', width: "80%" }}>
                {
                    sections.map(item => {
                        if (item.parent === null)
                            return (
                                <List dense={true} key={item.id}>
                                    <ListItem
                                        sx={{
                                            ":hover": {
                                                backgroundColor: "#0000000a"
                                            }
                                        }}
                                        secondaryAction={
                                            <>
                                                <IconButton edge="end" aria-label="delete"
                                                    onClick={() => dispatch(deleteType({
                                                        id: item.link_id,
                                                        section: id
                                                    }))}
                                                >
                                                    <DeleteIcon sx={{
                                                        width: '20px',
                                                        height: '20px',
                                                        color: "#f60607",
                                                    }} />
                                                </IconButton>
                                            </>
                                        }>
                                        <ListItemText primary={item.title} />
                                    </ListItem>
                                </List>
                            )
                    })
                }
                <Fab color='custom_purple' aria-label="add" sx={{
                    position: 'fixed',
                    bottom: '50px',
                    right: '50px'
                }}
                    onClick={() => setOpen(true)}>
                    <AddIcon />
                </Fab>
            </Box>
            <AddForm open={open} setOpen={setOpen} id={id} />
        </Box>
    )
}

export default PassportTemplateType