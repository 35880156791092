import { Box, Grid, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import MachineInfo from './MachineInfo'
import PassportEditore from './PassportEditore'


function Passport() {
    const { nomer } = useParams()

    const [page, setPage] = useState('info')

    const Switch = ({ page }) => {
        switch (page) {
            case 'info':
                return <MachineInfo nomer={nomer} />
            case 'passport':
                return <PassportEditore nomer={nomer} />
            default:
                return null
        }
    }

    return (
        <Grid container
            columnSpacing={3}
            sx={{
                height: '100%'
            }}
        >
            <Grid item xs={2} sx={{
                height: '100%'
            }}>
                <Box sx={{ display: 'block' }}>
                    <List sx={{
                        background: "#f5f6fa",
                        borderRadius: '4px',
                    }}>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => setPage('info')}>
                                <ListItemText primary={"Информация"} sx={{ fontWeight: 'Light' }} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => setPage('passport')}>
                                <ListItemText primary={"Паспорт"} sx={{ fontWeight: 'Light' }} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Grid>
            <Grid item xs={10}>
                <Switch page={page} />
            </Grid>
        </Grid >
    )
}

export default Passport