import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setValues } from '../../store/Passport/actions'
import * as PassportSelector from "../../store/Passport/selectors"

const Input = ({ itemElem, i, value }) => {
    const [val, setVal] = useState('')

    const dispatch = useDispatch()

    useEffect(() => {
        if (itemElem.type === "string") {
            if (typeof itemElem.value !== "undefined")
                setVal(itemElem.value)
        }

        if (itemElem.type === "int") {
            if (itemElem.items.length !== 0)
                itemElem.items.map(item => {
                    if (item.default)
                        setVal(item.id)

                })
        }

        return () => {
            setVal('')
        }
    }, [])

    useEffect(() => {
        if (typeof value !== "undefined" && val !== '') {
            if (itemElem.type === "string") {
                setVal(value.value)
            }

            if (itemElem.type === "int") {
                setVal(value.item)
            }
        }
    }, [value])

    useEffect(() => {
        if (itemElem.type === "string") {
            dispatch(setValues({
                i: i,
                id: itemElem.id,
                value: val,
                item: null,
                count: itemElem.count
            }))
        }

        if (itemElem.type === "int") {
            dispatch(setValues({
                i: i,
                id: itemElem.id,
                value: null,
                item: val,
                count: itemElem.count
            }))
        }
    }, [val])

    return <>
        {
            itemElem.type === "string" ? (
                <TextField
                    id={`input=${i}`}
                    type="text"
                    variant="outlined"
                    label={itemElem.title}
                    onChange={e => setVal(e.target.value)}
                    value={val}
                />
            ) : null
        }
        {
            itemElem.type === "int" ? (
                <TextField
                    id={`input=${i}`}
                    select
                    label={itemElem.title}
                    SelectProps={{
                        native: true,
                    }}
                    variant="outlined"
                    onChange={e => setVal(parseInt(e.target.value))}
                    value={val === '' ? 0 : parseInt(val)}
                >
                    <option value={0}>Выбрать</option>
                    {
                        itemElem.items.map(opt => <option key={opt.id} value={opt.id}>{opt.title}</option>)
                    }
                </TextField>
            ) : null
        }
    </>
}

export default Input