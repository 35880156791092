import { legacy_createStore as createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"

import { AuthReducer } from "./Auth/reducers"
import { MachineReducer } from "./Machine/reducers"
import { PassportReducer } from "./Passport/reducers"
import { DeviceReducer } from "./Device/reducers"

const rootReducers = combineReducers({
    AuthReducer,
    MachineReducer,
    PassportReducer,
    DeviceReducer
})

export default createStore(rootReducers, applyMiddleware(thunk))