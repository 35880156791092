import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Wrapper } from "./components";
import { Auth, LinkImei, Machine, Params, Passport, PassportTemplate, PassportTemplateType } from "./screens";
import { getUser, setAuth } from "./store/Auth/actions";
import * as AuthSelector from "./store/Auth/selectors"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
    const auth = useSelector(AuthSelector.auth)
    const token = useSelector(AuthSelector.token)

    const dispatch = useDispatch()

    useEffect(() => {
        if (token) {
            dispatch(getUser())
        } else {
            dispatch(setAuth(false))
        }
    }, [auth])

    if (auth === null) return <></>

    return (
        <Wrapper>
            {
                auth ? <Routes>
                    <Route path="/" element={<Machine />} />
                    <Route path="/passport/:nomer" element={<Passport />} />
                    <Route path="/params" element={<Params />} />
                    <Route path="/template-type" element={<PassportTemplateType />} />
                    <Route path="/template" element={<PassportTemplate />} />
                    <Route path="/imei" element={<LinkImei />} />
                </Routes> : <Auth />
            }
            <ToastContainer
                position="bottom-right"
            />
        </Wrapper>
    )
}

export default App;
