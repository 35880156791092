import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, List, ListItem, ListItemText, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import * as PassportSelector from "../../store/Passport/selectors"
import { addItem, getItemsForCode } from '../../store/Passport/actions';

function AddItem({ open, setOpen, id }) {
    const dispatch = useDispatch()

    const [title, setTitle] = useState('')
    const [code, setCode] = useState('')

    const search = useSelector(PassportSelector.search)

    const [codeError, setCodeError] = useState(false)

    const handleClose = () => {
        setOpen(false);
    };

    const handleCode = (e) => {
        setCode(e.target.value)
        setCodeError(e.target.value.length === 7 ? false : true)

        if (e.target.value.length === 7) {
            dispatch(getItemsForCode(e.target.value))
        }
    }

    useEffect(() => {
        if (search.length !== 0)
            setTitle(search.title)
    }, [search])

    const handleSend = (e) => {
        e.preventDefault()

        dispatch(addItem({
            "title": title,
            "code": code,
            "part_id": id,
        }))

        setTitle('')
        setCode('')
        setOpen(false)
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <form
                onSubmit={(e) => handleSend(e)}
                autoComplete="off"
            >
                <DialogTitle>
                    Добавить элемент
                    <IconButton
                        aria-label="close"
                        onClick={() => handleClose(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            error={codeError}
                            id="nomer"
                            label="Код (7 символов)"
                            type="text"
                            fullWidth
                            variant="outlined"
                            required={true}
                            value={code}
                            onChange={handleCode}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="sort"
                            label="Название"
                            type="text"
                            fullWidth
                            variant="outlined"
                            required={true}
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    {
                        code.length === 7 ? <Button type="submit">Добавить</Button> : null
                    }
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddItem