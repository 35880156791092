import {
    SET_DEVICE, SET_DEVICE_TYPE, SET_MACHINE_INFO
} from "./types";

const initialState = {
    device: [],
    types: [],
    info: []
}

export const DeviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DEVICE:
            return { ...state, device: action.payload }
        case SET_DEVICE_TYPE:
            return { ...state, types: action.payload }
        case SET_MACHINE_INFO:
            return { ...state, info: action.payload }
        default:
            return state
    }
}