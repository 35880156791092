import { Accordion, AccordionDetails, AccordionSummary, Button, Collapse, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addSection, getPassportParent, getPassportSection, sectionCopyFeld, setNewValues, setPassportParent, setPassportSection } from '../../store/Passport/actions'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as PassportSelector from "../../store/Passport/selectors"
import AddBoxIcon from '@mui/icons-material/AddBoxTwoTone';
import Input from './input'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function PassportEditore({ nomer }) {
    const dispatch = useDispatch()
    const [expanded, setExpanded] = useState(false)

    const passportParent = useSelector(PassportSelector.passportParent)
    const passportSection = useSelector(PassportSelector.passportSection)
    const value = useSelector(PassportSelector.value)

    useEffect(() => {
        dispatch(getPassportParent(nomer))

        return () => {
            dispatch(setPassportParent([]))
        }
    }, [])

    const handleChange = (panel, id) => (event, isExpanded) => {
        dispatch(setPassportSection([]))
        dispatch(setNewValues([]))

        if (isExpanded) {
            dispatch(getPassportSection(nomer, id))
        }

        setExpanded(isExpanded ? panel : false);
    }
    return (
        <>
            {
                passportParent.map((item, i) => {
                    return (
                        <Accordion key={item.id} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`, item.id)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                    {item.title}
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{item.comment}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    expanded === `panel${i}` ? (
                                        <Collapse in={true} timeout="auto" unmountOnExit>
                                            {
                                                passportSection.length !== 0 ? passportSection.map((itemElem, i) => (
                                                    <div key={i} className='passport_item_wrapper'>
                                                        <p>{itemElem.title}</p>
                                                        <Input itemElem={itemElem} i={i} value={value[i]} />
                                                        {
                                                            itemElem.multiply ?
                                                                <Button
                                                                    sx={{
                                                                        marginLeft: "15px"
                                                                    }}
                                                                    color='custom_purple'
                                                                    variant="contained"
                                                                    size='small'
                                                                    startIcon={<ContentCopyIcon />}
                                                                    onClick={() => dispatch(sectionCopyFeld(itemElem))}
                                                                > Копировать блок</Button> : null
                                                        }
                                                    </div>
                                                )) : null
                                            }
                                            <Button onClick={() => dispatch(addSection(value, nomer))} color='custom_purple' sx={{ marginTop: '15px' }} variant="contained" startIcon={<AddBoxIcon />}>Сохранить</Button>
                                        </Collapse>
                                    ) : null
                                }
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </>
    )
}

export default PassportEditore