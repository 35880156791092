export const SET_MACHINES = "SET_MACHINES"
export const SET_TYPE = "SET_TYPE"
export const SET_CLASS = "SET_CLASS"
export const SET_MODAL = "SET_MODAL"
export const SET_GEN = "SET_GEN"
export const SET_GEN_TYPE = "SET_GEN_TYPE"
export const SET_WORRANTYS = "SET_WORRANTYS"
export const SET_PERIOD = "SET_PERIOD"
export const SET_MODELS = "SET_MODELS"
export const SET_MACHINE_INFO = "SET_MACHINE_INFO"
export const SET_SELLERS = "SET_SELLERS"
export const SET_MANAGER = "SET_MANAGER"
export const SET_WORRANTY = "SET_WORRANTY"