import api from "../../services/api";
import { toast } from "react-toastify";
import { SET_DEVICE, SET_DEVICE_TYPE, SET_MACHINE_INFO } from "./types";
import { getMachineInfo } from "../Machine/actions";

export const setDevice = (data) => {
  return {
    type: SET_DEVICE,
    payload: data,
  };
};

export const getDevice =
  (page = 1, filter = 0) =>
  async (dispatch, getState) => {
    try {
      const pageParam = typeof page !== "undefined" ? `page=${page}` : "";

      let filterParam = "";
      switch (filter) {
        case 0:
          break;
        case 1:
          filterParam = "&work=true";
          break;
        case 2:
          filterParam = "&work=false";
          break;
        case 3:
          filterParam = "&expiringSoon=true";
          break;
        case 4:
          filterParam = "&expiredRecently=true";
          break;
        default:
          break;
      }

      const response = await api.get(
        `/api/v1/machines/getall?${pageParam}${filterParam}`,
        {}
      );

      if (response.status === 200) {
        const json = await response.data;
        dispatch(setDevice(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const setDeviceType = (data) => {
  return {
    type: SET_DEVICE_TYPE,
    payload: data,
  };
};

export const getDeviceType = () => async (dispatch, getState) => {
  try {
    const response = await api.get("/api/v1/machines/imei/type", {});

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setDeviceType(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const addDevice = (body) => async (dispatch, getState) => {
  try {
    const response = await api.post("/api/v1/machines/imei/add", body);

    if (response.status === 200) {
      dispatch(getMachineInfo(body.nomer));
      toast.success("IMEI и успешно добавлен");
    }
  } catch (e) {
    toast.error("Не удалось добавить IMEI");
    console.error(e);
  }
};

export const updateDevice = (body) => async (dispatch, getState) => {
  try {
    const response = await api.put("/api/v1/machines/imei/update", body);
    if (response.status === 200) {
      dispatch(getMachineInfo(body.nomer));
      toast.success("Успешно");
    }
  } catch (e) {
    toast.error("Не удалось выполнить операцию");
    console.error(e);
  }
};

export const setMachineInfo = (data) => {
  return {
    type: SET_MACHINE_INFO,
    payload: data,
  };
};

export const serchMachine = (nomer) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/machines/get/info?sn=${nomer}`, {});

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setMachineInfo(json.data));
    }
  } catch (e) {
    console.error(e);
  }
};
