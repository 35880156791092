import { getAccessToken } from "../store/Auth/actions"
import api from "./api"

const setup = (store) => {
    const endpointsSkip = [
        '/api/auth/login',
        '/api/auth/logout',
        '/api/auth/refresh-tokens'
    ]

    api.interceptors.request.use(async (config) => {
        if (config.url && endpointsSkip.includes(config.url)) {
            return config
        }

        const accessToken = await store.dispatch(getAccessToken())

        if (accessToken) {
            const autharization = `Bearer ${accessToken}`

            config.headers = {
                ...config.headers,
                authorization: autharization
            }
        }

        return config
    })

    api.interceptors.response.use(
        (response) => response,
        async (error) => {
            const isLoggedIn = !!store.getState().Auth.token

            if ((error.response?.status === 401) && isLoggedIn && error.request.url !== '/api/auth/logout') {
                //store.dispatch(logOut())
                await store.dispatch(getAccessToken())
            }

            return Promise.reject(error)
        }
    )
}

export default setup;
